import React, { useCallback, useEffect, useState } from 'react';

import { hideModal } from '../../../../shared/foreground/actions/modals';
import { globalState } from '../../../../shared/foreground/models';
import { updateExportClipboardTemplate } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/settings';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { DEFAULT_EXPORT_TEMPLATE, templateValidation } from '../../../../shared/foreground/utils/export';
import useIsModalShown from '../../utils/useIsModalShown';
import Button from '../Button';
import styles from './ExportTemplateModal.module.css';
import Modal from './Modal';

const id = 'export-template';

export default function ExportTemplateModal() {
  const isShown = useIsModalShown(id);
  const hide = useCallback(() => hideModal({ id }, { userInteraction: 'click' }), []);
  const exportTemplate = globalState(useCallback((state) => state.persistent.settings.exportClipboardTemplate, [])) ?? DEFAULT_EXPORT_TEMPLATE;
  const [value, setValue] = useState<string>();
  useEffect(() => setValue(exportTemplate), [exportTemplate, isShown]);

  const mainContent = <>
        <div className={styles.templateContainer}>
      <textarea
        value={value}
        name="template"
        aria-label="template"
        autoComplete="false"
        autoFocus
        className={`${styles.templateInput} ${styles.templateTextArea}`}
        onChange={(evt) => setValue(evt.target.value)}
      />
        </div>
    </>;

  const onClickUpdate = useCallback(async () => {
    if (value === undefined) {
      createToast({ content: 'No template found', category: 'error' });
      return;
    }
    const resp = await templateValidation(value);
    if (resp.valid) {
      await updateExportClipboardTemplate(value);
      createToast({ content: 'Export template updated', category: 'success' });
      hide();
    } else {
      createToast({ content: resp.error, category: 'error' });
    }
  }, [hide, value]);

  const onClickReset = useCallback(async () => {
    setValue(DEFAULT_EXPORT_TEMPLATE);
    createToast({ content: 'Reset complete', category: 'success' });
  }, []);

  const footerContent = <>
        <Button
          onClick={onClickReset}
          variant="default">
            Reset to default
        </Button>
        <Button
          onClick={hide}
          variant="secondary">
            Cancel
        </Button>
        <Button
          onClick={onClickUpdate}
          variant="primary">
            Save
        </Button>
    </>;

  return <>
        <Modal
          id={id}
          footerContent={footerContent}
          mainContent={mainContent}
          title="Export template"
          rootClassName={styles.modalRoot}
          mainContentClassName={styles.modalMain}
        />
    </>;
}
