import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import React from 'react';

import styles from './RadioGroup.module.css';

export default function RadioGroup({
  items,
  rootProps = {},
}: {
  items: {
    content: React.ReactNode | string;
    value: string;
  }[];
  rootProps?: RadixRadioGroup.RadioGroupProps;
}) {

  const itemElements = items.map((item) => {
    return <div
      key={item.value}
      className={`radioGroupItem ${styles.radioGroupItem}`}>

      <div>
        <RadixRadioGroup.Item
          className={`radioGroupButton ${styles.radioGroupButton}`}
          id={item.value}
          value={item.value}>
          <RadixRadioGroup.Indicator className={styles.radioGroupIndicator} />
        </RadixRadioGroup.Item>
      </div>

      <label
        className={styles.label}
        htmlFor={item.value}>
        {item.content}
      </label>
    </div>;
  });

  return <RadixRadioGroup.Root
    {...rootProps}
    className={styles.radioGroup}>
    {itemElements}
  </RadixRadioGroup.Root>;
}
